<template>
  <div class="app">
    <app-header fixed>
      <template slot="actions" v-if="profile">
        <div v-if="profile" class="float-right d-flex flex-row" data-private>
          <dropdown variant="link">
            <template slot="button-content">
              <b-img
                :src="profile.picture"
                :alt="profile.email"
                class="profile-avatar"
              />
            </template>
            <b-dropdown-item @click="logout">
              <fa-icon icon="lock" /> Log out
            </b-dropdown-item>
          </dropdown>
        </div>
      </template>
    </app-header>
    <div class="main-container container-fluid">
      <nuxt />
    </div>
    <div class="footer">
      <!-- div class="left-triangle"></div>
      <div class="right-triangle"></div-->
      <img
        class="triangles d-block d-sm-none"
        src="https://strossle-assets.s3-eu-west-1.amazonaws.com/other/Images/mobile-bottom.svg"
      />
      <img
        class="triangles d-none d-sm-block"
        src="https://strossle-assets.s3-eu-west-1.amazonaws.com/other/Images/bottom.svg"
      />
    </div>
    <vue-snotify />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppHeader from '~shared/components/commons/AppHeader.vue';
import Dropdown from '~shared/components/Dropdown.vue';

export default {
  components: {
    AppHeader,
    Dropdown,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('auth', {
      profile: (state) => state.user,
      isLoggedIn: (state) => state.loggedIn,
    }),
    getYear() {
      return new Date().getFullYear();
    },
    flash() {
      return this.$store.state.flash;
    },
  },
  watch: {
    flash() {
      if (this.flash) {
        const toast = this.$snotify[this.flash.type](
          this.flash.message,
          this.flash.title,
          this.flash.options
        );
        toast.on('destroyed', () => this.$store.commit('FLUSH_FLASH'));
      }
    },
  },
  methods: {
    logout() {
      this.$auth.logout();
    },
  },
};
</script>
<style scoped lang="scss">
.app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff !important;
}
.main-container {
  max-width: 1600px;
  padding-top: 72px;
  padding-bottom: 3rem;
  background: #fff;
}

.profile-avatar {
  height: 35px;
  border-radius: 50%;
}
.footer {
  margin-top: auto;
}
.footer img.triangles {
  width: 100% !important;
}
</style>
