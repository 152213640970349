import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0b6360a4 = () => interopDefault(import('../../src/my/pages/home.vue' /* webpackChunkName: "pages/home" */))
const _14e253f2 = () => interopDefault(import('../../src/my/pages/register.vue' /* webpackChunkName: "pages/register" */))
const _e9ea14b2 = () => interopDefault(import('../../src/my/pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _14557b8d = () => interopDefault(import('../../src/my/pages/auth/callback.vue' /* webpackChunkName: "pages/auth/callback" */))
const _76da7f5e = () => interopDefault(import('../../src/my/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _1ad65252 = () => interopDefault(import('../../src/my/pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _b88e2cda = () => interopDefault(import('../../src/my/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/home",
    component: _0b6360a4,
    name: "home"
  }, {
    path: "/register",
    component: _14e253f2,
    name: "register"
  }, {
    path: "/signup",
    component: _e9ea14b2,
    name: "signup"
  }, {
    path: "/auth/callback",
    component: _14557b8d,
    name: "auth-callback"
  }, {
    path: "/auth/login",
    component: _76da7f5e,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _1ad65252,
    name: "auth-logout"
  }, {
    path: "/",
    component: _b88e2cda,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
